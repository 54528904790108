.card-horizontal-feature-wrapper {
  overflow: hidden;
  @media (min-width: 768px) and (max-width: 1199px) {
    overflow: initial;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  @media screen and (max-width: 767px) {
    overflow: initial;
  }
}
.fixed-header .m-header {
  position: relative;
}
.sticky-search-top {
  top: 0 !important;
}
.sticky-search-container {
  position: fixed !important;
  top: 0 !important;
}
